.auth-label {
    display: flex;
    flex-direction: column;
    margin-bottom: 12px;
}

.auth-span {
    display: inline-block;
    margin-right: 7px;
}

.auth-btn {
    position: relative;
    min-width: 70px;
    min-height: 40px;
}